import CTABlock from 'components/umbraco/CTABlock';
import FAQBlock from 'components/umbraco/FAQBlock';
import CDBlock from 'components/umbraco/CDBlock';
import HeroBlock from 'components/umbraco/HeroBlock';
import HighlightsBlock from 'components/umbraco/HighlightsBlock';
import IFrameBlock from 'components/umbraco/IFrameBlock';
import SquaresBlock from 'components/umbraco/SquaresBlock';
import TextBlock from 'components/umbraco/TextBlock';
import FAQSquareBlock from 'components/umbraco/FAQSquareBlock';
import ContactFormBlock from 'components/umbraco/ContactFormBlock';
import BookingBlock from 'components/umbraco/BookingBlock';
import ImageBlock from 'components/umbraco/ImageBlock';
import { gql, GraphQLClient } from 'graphql-request';
import {
  BlockProps,
  BlockType,
  ContentPage,
  CtaProps,
  HeroProps,
  SquaresProps,
  HighlightsProps,
  FAQProps,
  FAQSquareProps,
  TextBlockProps,
  CDBlockProps,
  StartPage,
  MainMenu,
  IFrameProps,
  PageMetaData,
  UmbracoMetaData,
  FacilityContent,
  ContactFormProps,
  BookingProps,
  ImageProps,
  UmbracoRedirect
} from 'types/umbraco';
import Facility from 'pages/anlaggning/[slug]';

const headers = {
  'umb-project-alias': process.env.NEXT_PUBLIC_UMBRACO_PROJECT_ALIAS,
  'Accept-Language': 'sv-SE',
};

const client = new GraphQLClient('https://graphql.umbraco.io', {
  headers,
});

async function umbracoGetGrapgq(query: string) {
  const gQuery = gql`
    ${query}
  `;

  return await client.request(gQuery);
}

async function umbracoGet(path: string) {
  const resp = await fetch('https://cdn.umbraco.io/' + path, {
    headers,
  });

  if (resp.ok) {
    return await resp.json();
  }
}

async function umbracoGetWithRedirect(path: string) {
  var redirectResponse = await fetch(`https://cdn.umbraco.io/redirect/redirecturl?url=${path}`, {
    headers,
  });

  console.log(redirectResponse.ok)
  if(redirectResponse.ok)
  {
    const redirectBody = await redirectResponse.text();
    const redirectUrls: UmbracoRedirect = JSON.parse(redirectBody);
    path = redirectUrls.url; 
  }
  
  const resp = await fetch('https://cdn.umbraco.io/content/url?url=' + path, {
    headers,
  });

  if (resp.ok) {
    return await resp.json();
  }
}

export async function getStartpage(): Promise<StartPage> {
  const startpage = await umbracoGet(
    `content/${process.env.NEXT_PUBLIC_UMBRACO_STARTPAGE_ID}`
  );

  if (!startpage) return null;

  const blocks = await formatBlocks(startpage.blocks);

  return {
    blocks,
    meta: pageMetaDataFromUmbNode(startpage),
  };
}

export async function getContentPage(slug: string): Promise<ContentPage> {
  const contentpage = await umbracoGet(`content/url?url=/website/${slug}`);
  if (!contentpage) return null;

  const blocks = await formatBlocks(contentpage.blocks);

  return {
    blocks,
    meta: pageMetaDataFromUmbNode(contentpage),
  };
}

export async function getFooterMenu(): Promise<MainMenu> {
  const data = await umbracoGet(
    `content/${process.env.NEXT_PUBLIC_UMBRACO_STARTPAGE_ID}`
  );
  if (!data || !data.footerMenu) return null;

  return {
    items: data.footerMenu.map(
      (x: { _url: string; name: string; id: string; _id: string }) => {
        return {
          path: cleanUrl(x._url),
          name: x.name,
          id: x.id || x._id,
        };
      }
    ),
  };
}

function cleanUrl(url: string) {
  let urlParts = url.split('/');
  urlParts = urlParts.slice(2, urlParts.length);
  let cleanUrl = urlParts.join('/');

  if (cleanUrl.startsWith('/')) {
    cleanUrl = `${cleanUrl}`;
  }
  if (cleanUrl.endsWith('/')) {
    cleanUrl = cleanUrl.slice(0, -1);
  }

  return cleanUrl;
}

export async function getAllContentPagePaths(): Promise<
  { url: string; level: number; id: string }[]
> {
  const resp = await umbracoGetGrapgq(`
    {
      allContent {
        items {
          url,
          level,
          id,
          contentTypeAlias
        }
      }
    }
  `);

  return resp.allContent.items
    .filter((x) => x.contentTypeAlias === 'ContentPage')
    .map((x) => {
      x.url = cleanUrl(x.url);
      return x;
    });
}

async function formatBlocks(blocks: BlockType[]): Promise<BlockType[]> {
  if (!blocks) {
    return [];
  }

  return blocks;
}

export function BlockRenderer({ blocks }: BlockProps): JSX.Element {
  if (!blocks) {
    return null;
  }

  return (
    <div>
      {blocks.map((x, i) => {
        console.log(x, 'block');

        switch (x.contentTypeAlias) {
          case 'HeroBlock':
            return <HeroBlock key={i} {...(x as HeroProps)} />;

          case 'ctaBlock':
            return <CTABlock key={i} {...(x as CtaProps)} />;

          case 'squaresBlock':
            return <SquaresBlock key={i} {...(x as SquaresProps)} />;

          case 'highlightsBlock':
            return <HighlightsBlock key={i} {...(x as HighlightsProps)} />;

          case 'faqBlock':
            return <FAQBlock key={i} {...(x as FAQProps)} />;

          case'cookieDeclarationBlock':
            return <CDBlock key={i} {...(x as CDBlockProps)} />;

          case 'textBlock':
            return <TextBlock key={i} {...(x as TextBlockProps)} />;

          case 'iframeBlock':
            return <IFrameBlock key={i} {...(x as IFrameProps)} />;

          case 'faqSquareBlock':
            return <FAQSquareBlock key={i} {...(x as FAQSquareProps)} />;

          case 'companyFormBlock':
            return <ContactFormBlock key={i} {...(x as ContactFormProps)} />

          case 'BookingBlock':
              return <BookingBlock key={i} {...(x as BookingProps)} />

          case 'imageBlock':
            return <ImageBlock key={i} {...(x as ImageProps)} />
          default:
            break;
        }
      })}
    </div>
  );
}

export async function GetFacility(slug: string): Promise<FacilityContent> {
  const facilityContent = await umbracoGetWithRedirect(
    `/website/anlaggningar/${slug}`
  );
  if (!facilityContent) return null;

  facilityContent.meta = pageMetaDataFromUmbNode(facilityContent);
  return facilityContent;
}

export const GetFacilitiesContent = async (): Promise<any> => {
  const returnObj = { facilities: null, content: null }
  const facilityQuery = `query {
  allFacility(orderBy:name_ASC) {
    items {
      id
      name
      address
      url
      image {
        id
        url
      }
    }
  }
}`
  const facilities = await umbracoGetGrapgq(facilityQuery)
  if (facilities) {
    returnObj.facilities = facilities.allFacility.items
  }
  const facilityContentQuery = `query {
  facilitiesArchive(id: "${process.env.NEXT_PUBLIC_UMBRACO_FACILITYPAGE_ID}") {
    id
    header
    rteText
  }
}
`
  const facilityContent = await umbracoGetGrapgq(facilityContentQuery)
  if (facilityContent) {
    returnObj.content = facilityContent.facilitiesArchive
  }
  return returnObj
}



function pageMetaDataFromUmbNode(pageData: UmbracoMetaData): PageMetaData {
  return {
    title: pageData?.alternativeTitle,
    description: pageData?.metaDescription,
    nodeName: pageData?.name,
  };
}
