import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import SessionProvider from '../session/SessionProvider';

import Head from 'next/head';
import { theme } from '../constants/_theme';
import { useRouter } from 'next/router';
import { Layout } from 'components/Layout';
import { useEffect } from 'react';
import '../styles.css'; // do not remove! imports fonts
import { init } from '../helpers/sentry';
import * as gtag from '../helpers/gtag';

init();

export default function MyApp({
  Component,
  pageProps,
}: {
  Component: React.ComponentType;
  pageProps: Record<string, unknown>;
}): JSX.Element {
  useDevelopmentPageLoader();
  useFlowWidget();

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <ChakraProvider theme={theme}>
        <Head>
          {/* Cookiebot script*/}
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" 
        data-cbid="fd32389e-5c40-4178-b5cf-dd2cc6d0bd87" 
        data-blockingmode="auto" 
        type="text/javascript">
        </script>
        
          {/* <meta name="facebook-domain-verification" content="g744jvxa9s4lsedc6e36kq2thstfp8" /> */}
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link rel="preconnect" href="https://www.gstatic.com" />
          <link rel="preconnect" href="https://o437175.ingest.sentry.io" />
          <link rel="preconnect" href="https://relay.telavox.com" />
          <link key="favicon" rel="icon" href="favicon.ico" />
          {process.env.NODE_ENV === 'development' && (
            <>
              <script src="https://unpkg.com/nprogress@0.2.0/nprogress.js"></script>
              <link
                rel="stylesheet"
                href="https://unpkg.com/nprogress@0.2.0/nprogress.css"
              />
            </>
          )}
        </Head>
        <SessionProvider>
          <Layout {...Object.assign({}, pageProps.sharedPageProps)}>
            <Component {...pageProps} />
          </Layout>
        </SessionProvider>
      </ChakraProvider>
    </>
  );
}

function useDevelopmentPageLoader() {
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = () => {
      window['NProgress'].start();
    };

    const handleRouteChangeComplete = () => {
      window['NProgress'].done();
    };

    if (process.env.NODE_ENV === 'development') {
      router.events.on('routeChangeStart', handleRouteChange);
      router.events.on('routeChangeComplete', handleRouteChangeComplete);
    }

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);
}

function useFlowWidget() {
  useEffect(() => {
    const onLoad = () => {
      setTimeout(() => {
        const script = document.createElement('script');
        script.src =
          'https://customerwidget.joinflow.com/tvx-customer-widget-app.js?widgetId=' +
          process.env.NEXT_PUBLIC_FLOW_WIDGET_ID;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        document.removeEventListener('load', onLoad);
      }, 4000);
    };
    if (!process.env.NEXT_PUBLIC_FLOW_WIDGET_ID) return;
    window.addEventListener('load', onLoad);
    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, []);
}
